import styled, { css } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

export const Type = {
  CIRCLE: "circle",
  UNSTYLED: "unstyled",
}

const propTypes = {
  type: PropTypes.oneOf(Object.values(Type)),
  textColor: PropTypes.string,
  circleColor: PropTypes.string,
}

export const ListItem = styled.li`
  display: flex;
  align-items: center;
`
export const List = styled.ul`
  margin: 0;
  padding: 0;
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => props.textColor ?? props.theme.color.text};

  > ${ListItem} {
    display: inline-flex;
    width: 100%;
    margin: ${props => rem(`${props.type === Type.CIRCLE ? 19 : 7}px`)} 0;

    ${props =>
      props.type === Type.CIRCLE &&
      css`
        &::before {
          content: "";
          flex-shrink: 0;
          align-self: flex-start;
          order: -1;
          border: 2px solid
            ${props => props.circleColor ?? props.theme.color.primary};
          border-radius: 50%;
          margin-right: ${rem("15px")};
          height: 0.5em;
          width: 0.5em;
        }
      `}
  }
`

List.propTypes = propTypes
